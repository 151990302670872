<template>
  <div class="page page-profile">
    <div class="v-px-lg v-py-md" style="margin: 0 auto; width: 850px">
      <div class="panel" v-if="(user.state === 2 || user.state === 3) && refer !== 'project'">
        <div class="panel-header">
          <div class="panel-header-title">
            <a-icon type="swap-right" />
            申请人账户信息
          </div>
        </div>
        <a-divider class="bg-primary"></a-divider>
        <div class="panel-body">
          <table class="table table-bald">
            <tbody>
              <tr>
                <th class="text-right" width="160">用户名</th>
                <td>{{ userinfo.userName }}</td>
              </tr>
              <tr>
                <th class="text-right">注册手机</th>
                <td>
                  {{ $utils.blurPhone(user.phone) }}
                  <span class="v-ml-xs text-indigo hover-line" title="修改注册手机号" @click="handleUpdatePhone">修改</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="panel">
        <template v-if="user.state === 2 && refer === 'project'">
          <div class="text-center font-md text-red v-mb-xs">申报项目前，请先完善申请人信息</div>
        </template>
        <template v-else>
          <div class="panel-header">
            <div class="panel-header-title">
              <a-icon type="swap-right" />
              申请人基本信息
            </div>
            <div class="panel-header-action" v-if="user.state !== 2">
              <a-space>
                <span class="text-indigo hover-line" @click="handleEdit" v-if="!editing">
                  <!-- <a-icon type="form" /> -->
                  修改
                </span>
                <template v-else>
                  <!-- <a-button class="text-primary" type="link" size="small" icon="check" :loading="saving" @click="onSubmit">保存</a-button> -->
                  <span class="text-primary hover-line" @click="onSubmit(false)">
                    <a-icon :type="saving ? 'loading' : 'check'" />
                    保存
                  </span>
                  <span class="text-grey hover-line" @click="cancelEdit">
                    <a-icon type="close" />
                    取消
                  </span>
                </template>
              </a-space>
            </div>
          </div>
          <a-divider class="bg-primary v-my-md"></a-divider>
        </template>
        <div class="panel-body">
          <a-alert class="v-mb-sm" type="error" v-if="user.state === 2 && refer !== 'project'">
            <span slot="message" class="text-red">请先完善申请人信息</span>
          </a-alert>
          <table class="table table-bald">
            <tbody>
              <tr>
                <th class="text-right" width="160">申请人类型</th>
                <td>{{ user.userType === 1 ? '机构' : '个人' }}</td>
              </tr>
              <tr>
                <th class="text-right">申请人名称</th>
                <td>{{ user.realName }}</td>
              </tr>
              <template v-if="user.userType === 2">
                 <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    国籍
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.nationality }}</span>
                    <a-select placeholder="请选择" v-model="editForm.nationality" ref="nationality" style="width: 200px" v-else>
                      <a-select-option v-for="item in nationalities" :key="item.label">{{ item.label }}</a-select-option>
                    </a-select>
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    身份证件号码
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.code }}</span>
                    <a-input placeholder="证件号码" v-model="editForm.code" ref="ucode" style="width: 200px" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    团队名称
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.team }}</span>
                    <a-input placeholder="团队名称" v-model="editForm.team" ref="team" style="width: 90%" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    所在社区
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.community }}</span>
                    <a-input placeholder="社区名称" v-model="editForm.community" ref="community" style="width: 90%" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    团队成员及分工
                  </th>
                  <td>
                    <div v-if="!editing" v-html="$utils.convertToHtml(user.teamDivision)"></div>
                    <a-textarea placeholder="团队成员及分工信息" :rows="4" v-model="editForm.teamDivision" style="width: 90%" ref="teamDivision" v-else />
                  </td>
                </tr>
              </template>
              <template v-if="user.userType === 1">
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    机构性质
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.orgType }}</span>
                    <a-select style="width: 200px" placeholder="请选择" v-model="editForm.orgType" ref="orgType" v-else>
                      <a-select-option v-for="item in orgTypes" :key="item.label">{{ item.label }}</a-select-option>
                    </a-select>
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    机构代码
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.code }}</span>
                    <a-input v-model="editForm.code" style="width: 200px" ref="code" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    机构登记证书
                  </th>
                  <td>
                    <template v-if="!editing">
                      <file-container :files="djzsFileList.concat(djzsFileList2)" :belongId="user.id" fileSign="U01" v-if="user.id"></file-container>
                      <!-- <div v-for="file in djzsFileList" :key="file.id">
                      <span class="text-indigo hover-line" :title="file.name">{{ file.name }}</span>
                    </div>
                    <div v-for="file in djzsFileList2" :key="file.id">
                      <span class="text-indigo hover-line" :title="file.name">{{ file.name }}</span>
                    </div> -->
                    </template>
                    <div style="width: 90%" v-else>
                      <div class="font-xs v-my-xs lineh-14 text-grey text-justify">请上传机构登记证书的复印件，加盖公章并写明“用于千禾公益基金会网站注册申请”的字样。（不接受PS图片且必须保证证件信息清晰可见）：</div>
                      <a-upload
                        class="avatar-uploader"
                        list-type="picture-card"
                        :action="`${baseURL}/file/upload`"
                        :data="{
                          params: JSON.stringify({ fileBelongId: user.id, fileType: '101', fileSign: 'U01' }),
                        }"
                        :show-upload-list="{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }"
                        :file-list="djzsFileList"
                        :before-upload="beforeUploadFile"
                        :class="{ 'upload-hideonuploaded': djzsFileList.length > 0 }"
                        @preview="handlePreviewFile"
                        @change="handleUploadDjzsFileChange"
                        @download="({ fileId, name }) => downloadFile({ fileId, belongId: user.id, fileSign: 'U01', fileName: name })"
                        accept=".png, .jpg, .jpeg"
                      >
                        <div class="v-pa-xs" v-if="djzsFileList.length === 0">
                          <a-icon type="plus" />
                          <p class="font-xs">登记证书</p>
                        </div>
                      </a-upload>
                      <div class="font-xs v-my-xs lineh-14 text-grey text-justify">如因换证等原因导致证件上没有法定注册时间，请上传可证明此的其他证据（选填）：</div>
                      <a-upload-dragger
                        class="avatar-uploader"
                        list-type="picture-card"
                        :action="`${baseURL}/file/upload`"
                        :data="{
                          params: JSON.stringify({ fileBelongId: user.id, fileType: '102', fileSign: 'U01' }),
                        }"
                        :show-upload-list="{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }"
                        :file-list="djzsFileList2"
                        :before-upload="beforeUploadFile"
                        :class="{ 'upload-hideonuploaded': djzsFileList2.length > 0 }"
                        @preview="handlePreviewFile"
                        @change="handleUploadDjzsFile2Change"
                        @download="({ fileId, name }) => downloadFile({ fileId, belongId: user.id, fileSign: 'U01', fileName: name })"
                        accept=".png, .jpg, .jpeg"
                      >
                        <div class="v-pa-xs" v-if="djzsFileList2.length === 0">
                          <a-icon type="plus" />
                          <p class="font-xs">其他证明</p>
                        </div>
                      </a-upload-dragger>
                    </div>
                  </td>
                </tr>
              </template>
              <tr>
                <th class="text-right">
                  <i class="required-mark" v-if="editing">*</i>
                  联系人
                </th>
                <td>
                  <span v-if="!editing">{{ user.contactPerson }}</span>
                  <a-input v-model="editForm.contactPerson" style="width: 200px" placeholder="姓名" ref="contactPerson" v-else />
                </td>
              </tr>
              <tr>
                <th class="text-right">
                  <i class="required-mark" v-if="editing">*</i>
                  联系人电话
                </th>
                <td>
                  <span v-if="!editing">{{ user.contactPhone }}</span>
                  <a-input v-model="editForm.contactPhone" style="width: 200px" placeholder="电话/手机号码" ref="contactPhone" v-else />
                </td>
              </tr>
              <template v-if="user.userType === 1">
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    组织愿景和使命
                  </th>
                  <td class="text-justify">
                    <div v-if="!editing" v-html="$utils.convertToHtml(user.orgRemark)"></div>
                    <a-textarea :rows="4" v-model="editForm.orgRemark" style="width: 90%" ref="orgRemark" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    业务主管单位
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.orgMaster }}</span>
                    <a-input v-model="editForm.orgMaster" style="width: 90%" ref="orgMaster" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    注册地
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.province }}{{ user.city }}{{ user.district }}{{ user.addr }}</span>
                    <a-input-group compact v-else>
                      <a-cascader style="width: 210px" :show-search="{ cascaderFilter }" :options="districts" placeholder="选择地区" v-model="editForm.districts" ref="district" />
                      <a-input style="width: calc(90% - 210px)" placeholder="详细地址" v-model="editForm.addr" ref="addr" />
                    </a-input-group>
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    理事会信息
                  </th>
                  <td>
                    <div v-if="!editing" v-html="$utils.convertToHtml(user.councilInfo)"></div>
                    <a-textarea :rows="4" v-model="editForm.councilInfo" style="width: 90%" ref="councilInfo" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    组织架构
                  </th>
                  <td>
                    <template v-if="!editing">
                      <file-container :files="zzjgFileList" :belongId="user.id" fileSign="U01" v-if="zzjgFileList && user.id"></file-container>
                    </template>
                    <div style="width: 90%" v-else>
                      <a-upload-dragger
                        class="upload-block"
                        :action="`${baseURL}/file/upload`"
                        :data="{
                          params: JSON.stringify({ fileBelongId: user.id, fileType: '103', fileSign: 'U01' }),
                        }"
                        :show-upload-list="{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }"
                        :file-list="zzjgFileList"
                        :before-upload="beforeUploadFile"
                        :class="{ 'upload-hideonuploaded': zzjgFileList.length > 0 }"
                        @preview="handlePreviewFile"
                        @change="handleUploadZzjgFileChange"
                        @download="({ fileId, name }) => downloadFile({ fileId, belongId: user.id, fileSign: 'U01', fileName: name })"
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                      >
                        <div class="v-pa-xs" v-if="zzjgFileList.length === 0">
                          <a-icon type="plus" />
                          <p class="font-xs">组织架构</p>
                        </div>
                      </a-upload-dragger>
                    </div>
                  </td>
                </tr>
              </template>
              <tr>
                <th class="text-right" valign="top">
                  <i class="required-mark" v-if="editing">*</i>
                  开户银行信息
                </th>
                <td>
                  <template v-if="!editing">
                    <div v-show="editForm.bankName">
                      <span class="text-grey">开户银行：</span>
                      <span>{{ editForm.bankName }}</span>
                    </div>
                    <div v-show="editForm.bankAccount">
                      <span class="text-grey">账户名称：</span>
                      <span>{{ editForm.bankAccount }}</span>
                    </div>
                    <div v-show="editForm.bankCard">
                      <span class="text-grey">账户卡号：</span>
                      <span>{{ editForm.bankCard }}</span>
                    </div>
                  </template>
                  <div style="width: 90%" v-else>
                    <div class="flex items-center">
                      <span class="text-right">开户银行：</span>
                      <a-input class="col" placeholder="开户行" v-model="editForm.bankName" ref="bankName" />
                    </div>
                    <div class="flex items-center v-mt-xs">
                      <span class="text-right">账户名称：</span>
                      <a-input class="col" placeholder="账户名称" v-model="editForm.bankAccount" ref="bankAccount" />
                    </div>
                    <div class="flex items-center v-mt-xs">
                      <span class="text-right">账户卡号：</span>
                      <a-input class="col" placeholder="银行卡号" v-model="editForm.bankCard" ref="bankCard" />
                    </div>
                  </div>
                </td>
              </tr>
              <template v-if="user.userType === 1">
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    财务制度
                  </th>
                  <td>
                    <template v-if="!editing">
                      <file-container :files="cwzdFileList" :belongId="user.id" fileSign="U01" v-if="cwzdFileList && user.id"></file-container>
                      <!-- <div v-for="file in cwzdFileList" :key="file.id">
                      <span class="text-indigo hover-line" :title="file.name">{{ file.name }}</span>
                    </div> -->
                    </template>
                    <div style="width: 90%" v-else>
                      <a-upload-dragger
                        class="upload-block"
                        :action="`${baseURL}/file/upload`"
                        :data="{
                          params: JSON.stringify({ fileBelongId: user.id, fileType: '104', fileSign: 'U01' }),
                        }"
                        :show-upload-list="{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }"
                        :file-list="cwzdFileList"
                        :before-upload="beforeUploadFile"
                        :class="{ 'upload-hideonuploaded': cwzdFileList.length > 0 }"
                        @preview="handlePreviewFile"
                        @change="handleUploadCwzdFileChange"
                        @download="({ fileId, name }) => downloadFile({ fileId, belongId: user.id, fileSign: 'U01', fileName: name })"
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                      >
                        <div class="v-pa-xs" v-if="cwzdFileList.length === 0">
                          <a-icon type="plus" />
                          <p class="font-xs">财务制度</p>
                        </div>
                      </a-upload-dragger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    财务和出纳人员信息
                  </th>
                  <td>
                    <div v-if="!editing" v-html="$utils.convertToHtml(user.financialInfo)"></div>
                    <a-textarea :rows="4" v-model="editForm.financialInfo" style="width: 90%" ref="financialInfo" v-else />
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    年报
                  </th>
                  <td>
                    <template v-if="!editing">
                      <file-container :files="nbFileList" :belongId="user.id" fileSign="U01" v-if="nbFileList && user.id"></file-container>
                      <!-- <div v-for="file in nbFileList" :key="file.id">
                      <span class="text-indigo hover-line" :title="file.name">{{ file.name }}</span>
                    </div> -->
                    </template>
                    <div style="width: 90%" v-else>
                      <a-upload-dragger
                        class="upload-block"
                        :action="`${baseURL}/file/upload`"
                        :data="{
                          params: JSON.stringify({ fileBelongId: user.id, fileType: '105', fileSign: 'U01' }),
                        }"
                        :show-upload-list="{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }"
                        :file-list="nbFileList"
                        :before-upload="beforeUploadFile"
                        :class="{ 'upload-hideonuploaded': nbFileList.length > 0 }"
                        @preview="handlePreviewFile"
                        @change="handleUploadNbFileChange"
                        @download="({ fileId, name }) => downloadFile({ fileId, belongId: user.id, fileSign: 'U01', fileName: name })"
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                      >
                        <div class="v-pa-xs" v-if="nbFileList.length === 0">
                          <a-icon type="plus" />
                          <p class="font-xs">年报</p>
                        </div>
                      </a-upload-dragger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-right" valign="top">
                    <i class="required-mark" v-if="editing">*</i>
                    审计报告
                  </th>
                  <td>
                    <template v-if="!editing">
                      <file-container :files="sjbgFileList" :belongId="user.id" fileSign="U01" v-if="sjbgFileList && user.id"></file-container>
                      <!-- <div v-for="file in sjbgFileList" :key="file.id">
                      <span class="text-indigo hover-line" :title="file.name">{{ file.name }}</span>
                    </div> -->
                    </template>
                    <div style="width: 90%" v-else>
                      <a-upload-dragger
                        class="upload-block"
                        :action="`${baseURL}/file/upload`"
                        :data="{
                          params: JSON.stringify({ fileBelongId: user.id, fileType: '106', fileSign: 'U01' }),
                        }"
                        :show-upload-list="{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }"
                        :file-list="sjbgFileList"
                        :before-upload="beforeUploadFile"
                        :class="{ 'upload-hideonuploaded': sjbgFileList.length > 0 }"
                        @preview="handlePreviewFile"
                        @change="handleUploadSjbgFileChange"
                        @download="({ fileId, name }) => downloadFile({ fileId, belongId: user.id, fileSign: 'U01', fileName: name })"
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                      >
                        <div class="v-pa-xs" v-if="sjbgFileList.length === 0">
                          <a-icon type="plus" />
                          <p class="font-xs">审计报告</p>
                        </div>
                      </a-upload-dragger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-right">
                    <i class="required-mark" v-if="editing">*</i>
                    是否有免税资格
                  </th>
                  <td>
                    <span v-if="!editing">{{ user.isTaxFree ? '是' : '否' }}</span>
                    <a-switch checked-children="是" un-checked-children="否" v-model="editForm.isTaxFree" v-else />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-center v-mt-sm" v-if="user.state === 2">
            <a-space v-if="refer === 'project'">
              <a-button type="primary" icon="check" :loading="saving" @click="onSubmit(true)">保存，去填报项目意向</a-button>
              <a-button type="default" icon="rollback" @click="$router.go(-1)">取消</a-button>
            </a-space>
            <a-button type="primary" icon="check" :loading="saving" @click="onSubmit(false)" v-else>保存</a-button>
          </div>
        </div>
      </div>

      <!-- 修改手机 -->
      <a-modal :confirmLoading="phoneSaving" :visible="showPhoneModal" :width="400" @cancel="showPhoneModal = false" @ok="submitPhone" centered okText="确认修改" title="修改注册手机">
        <a-form autocomplete="off">
          <a-form-item :colon="false" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
            <span slot="label">
              <i class="required-mark">*</i>
              原注册手机
            </span>
            <a-input type="text" placeholder="原手机号码" v-model="phoneForm.phone" ref="phone" autoComplete="new-password" />
          </a-form-item>
          <a-form-item :colon="false" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
            <span slot="label">
              <i class="required-mark">*</i>
              新注册手机
            </span>
            <a-input type="text" placeholder="新手机号码" v-model="phoneForm.newPhone" ref="newPhone" autoComplete="new-password" />
          </a-form-item>
          <a-form-model-item :colon="false" prop="smscode" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
            <span slot="label">
              <i class="required-mark">*</i>
              验证码
            </span>
            <a-input size="large" v-model="phoneForm.smscode" placeholder="短信验证码" ref="smscode" :max-length="6">
              <!-- <a-icon slot="prefix" type="mail" /> -->
              <span slot="suffix" class="sms-send text-primary cursor-pointer" @click="showBox" v-if="!verified">发送验证码</span>
              <span slot="suffix" class="sms-send text-grey" v-else>
                重新发送（
                <i>{{ second }}</i>
                s）
              </span>
            </a-input>
          </a-form-model-item>
          <Verify @success="verifySuccess" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Verify from './common/captcha/Verify'
import { fetchById, saveUser, changePhoneSms, changePhone } from '@/api/user'
import districts from '@/utils/districts'
const form = {
  orgType: '社会团体',
  code: '',
  orgRegDate: undefined,
  realName: '',
  orgRemark: '',
  orgMaster: '',
  contactPerson: '',
  contactPhone: '',
  province: undefined,
  city: undefined,
  addr: '',
  councilInfo: '',
  financialInfo: '',
  isTasFree: false,
  bankName: '',
  bankCard: '',
  bankAccount: '',
  districts: [],
  fiels: [],
  nationality: '中国大陆籍',
  team: '',
  community: '',
  teamDivision: '',
}
export default {
  name: 'Profile',
  components: {
    Verify,
  },
  data() {
    return {
      refer: this.$route.query.refer,
      user: {},
      saving: false,
      phoneSaving: false,
      editing: false,
      showPhoneModal: false,
      editForm: { ...form },
      districts,
      djzsFileList: [],
      djzsFileList2: [],
      zzjgFileList: [],
      cwzdFileList: [],
      nbFileList: [],
      sjbgFileList: [],
      phoneForm: {
        phone: '',
        newPhone: '',
        smscode: '',
      },
      second: 60,
      verified: false,
      timer: null,
    }
  },
  computed: {
    orgTypes: vm => vm.$store.state.app.orgTypes,
    nationalities: vm => vm.$store.state.app.nationalities,
  },
  watch: {
    second(val) {
      if (val <= 0) {
        this.verified = false
        this.clearTimer()
      }
    },
  },
  methods: {
    init() {
      let { id } = this.userinfo
      fetchById(id).then(({ data }) => {
        if (data.success) {
          let user = data.result
          user.isTaxFree = !!user.isTaxFree
          this.user = user
          this.fillEditForm()
          if (user.state === 2) {
            this.editing = true
          }
        }
      })
    },
    showBox() {
      this.$message.destroy()
      let { newPhone } = this.phoneForm
      if (!this.$utils.isValidPhone(newPhone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.newPhone.focus()
        return
      }
      this.$refs.verify.show()
    },
    verifySuccess(params) {
      this.$message.destroy()
      let { newPhone } = this.phoneForm
      changePhoneSms({ phone: newPhone, captchaVerification: params.captchaVerification })
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('验证码已发送至手机')
            this.verified = true
            this.second = 60
            this.startCountdown()
          } else {
            this.$message.warning(data.msg)
          }
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
        })
    },
    startCountdown() {
      this.clearTimer()
      let vm = this
      const countdown = () => {
        vm.second--
      }
      vm.timer = setInterval(() => {
        countdown()
      }, 1000)
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    fillEditForm() {
      let { files, province, city, district } = this.user
      let djzsFileList = []
      let djzsFileList2 = []
      let zzjgFileList = []
      let cwzdFileList = []
      let nbFileList = []
      let sjbgFileList = []
      files.forEach(file => {
        let { fileType, id, fileOriginName } = file
        let uploadedFile = {
          uid: id,
          fileId: id,
          name: fileOriginName,
          status: 'done',
          belongId: this.user.id,
          fileSign: 'U01',
          ...file,
          url: `${this.baseURL}/file/img?id=${id}&belongId=${this.user.id}&fileSign=U01`,
        }
        if (fileType === '101') {
          djzsFileList.push(uploadedFile)
        } else if (fileType === '102') {
          djzsFileList2.push(uploadedFile)
        } else if (fileType === '103') {
          zzjgFileList.push(uploadedFile)
        } else if (fileType === '104') {
          cwzdFileList.push(uploadedFile)
        } else if (fileType === '105') {
          nbFileList.push(uploadedFile)
        } else if (fileType === '106') {
          sjbgFileList.push(uploadedFile)
        }
      })
      let districts = [province, city, district]
      this.djzsFileList = djzsFileList
      this.djzsFileList2 = djzsFileList2
      this.zzjgFileList = zzjgFileList
      this.cwzdFileList = cwzdFileList
      this.nbFileList = nbFileList
      this.sjbgFileList = sjbgFileList
      this.editForm = { ...form, ...this.user, districts }
    },
    handleEdit() {
      this.editing = true
    },
    cancelEdit() {
      this.fillEditForm()
      this.editing = false
    },
    onSubmit(apply = false) {
      if (this.saving) {
        return
      }
      let { orgType, userType, code, orgRemark, orgMaster, districts, addr, contactPerson, contactPhone, councilInfo, financialInfo, bankName, bankCard, bankAccount, isTaxFree, team, community, teamDivision } = this.editForm
      let { djzsFileList, djzsFileList2, zzjgFileList, cwzdFileList, nbFileList, sjbgFileList } = this
      this.$message.destroy()
      if (userType === 1) {
        if (!orgType) {
          this.$refs.orgType.focus()
          this.$message.warning('请选择机构性质')
          return
        }
        if (code.trim() === '') {
          this.$refs.code.focus()
          this.$message.warning('请输入机构代码')
          return
        }
        if (djzsFileList.length === 0) {
          this.$message.warning('请上传机构登记证书')
          return
        }
        if (contactPerson.trim() === '') {
          this.$refs.contactPerson.focus()
          this.$message.warning('请输入联系人姓名')
          return
        }
        if (contactPhone.trim() === '') {
          this.$refs.contactPhone.focus()
          this.$message.warning('请输入联系人电话')
          return
        }
        if (orgRemark.trim() === '') {
          this.$refs.orgRemark.focus()
          this.$message.warning('请输入组织愿景和使命')
          return
        }
        if (orgMaster.trim() === '') {
          this.$refs.orgMaster.focus()
          this.$message.warning('请输入业务主管单位')
          return
        }
        if (districts.length === 0) {
          this.$refs.district.focus()
          this.$message.warning('请选择注册地区')
          return
        }
        if (addr.trim() === '') {
          this.$refs.addr.focus()
          this.$message.warning('请输入详细地址')
          return
        }
        if (councilInfo.trim() === '') {
          this.$refs.councilInfo.focus()
          this.$message.warning('请输入理事会信息')
          return
        }
        if (zzjgFileList.length === 0) {
          this.$message.warning('请上传组织架构')
          return
        }
        if (bankName.trim() === '') {
          this.$refs.bankName.focus()
          this.$message.warning('请输入开户银行')
          return
        }
        if (bankAccount.trim() === '') {
          this.$refs.bankAccount.focus()
          this.$message.warning('请输入账户名称')
          return
        }
        if (bankCard.trim() === '') {
          this.$refs.bankCard.focus()
          this.$message.warning('请输入账户卡号')
          return
        }
        if (cwzdFileList.length === 0) {
          this.$message.warning('请上传财务制度')
          return
        }
        if (financialInfo.trim() === '') {
          this.$refs.financialInfo.focus()
          this.$message.warning('请输入理事会信息')
          return
        }
        if (nbFileList.length === 0) {
          this.$message.warning('请上传年报')
          return
        }
        if (sjbgFileList.length === 0) {
          this.$message.warning('请上传审计报告')
          return
        }
      } else if (userType === 2) {
        if (code.trim() === '') {
          this.$refs.ucode.focus()
          this.$message.warning('请输入身份证件号码')
          return
        }
        if (!this.$utils.isValidIDCard(code.trim())) {
          this.$refs.ucode.focus()
          this.$message.warning('请输入正确的身份证件号码')
          return
        }
        if (team.trim() === '') {
          this.$refs.team.focus()
          this.$message.warning('请输入团队名称')
          return
        }
        if (community.trim() === '') {
          this.$refs.community.focus()
          this.$message.warning('请输入所在社区')
          return
        }
        if (teamDivision.trim() === '') {
          this.$refs.teamDivision.focus()
          this.$message.warning('请输入团队成员及分工')
          return
        }
        if (contactPerson.trim() === '') {
          this.$refs.contactPerson.focus()
          this.$message.warning('请输入联系人姓名')
          return
        }
        if (contactPhone.trim() === '') {
          this.$refs.contactPhone.focus()
          this.$message.warning('请输入联系人电话')
          return
        }
        if (bankName.trim() === '') {
          this.$refs.bankName.focus()
          this.$message.warning('请输入开户银行')
          return
        }
        if (bankAccount.trim() === '') {
          this.$refs.bankAccount.focus()
          this.$message.warning('请输入账户名称')
          return
        }
        if (bankCard.trim() === '') {
          this.$refs.bankCard.focus()
          this.$message.warning('请输入账户卡号')
          return
        }
      }
      let fileIds = djzsFileList
        .concat(djzsFileList2)
        .concat(zzjgFileList)
        .concat(cwzdFileList)
        .concat(nbFileList)
        .concat(sjbgFileList)
        .map(file => file.fileId)

      let params = {
        id: this.user.id,
        orgType,
        code,
        orgRemark,
        orgMaster,
        districts,
        addr,
        councilInfo,
        financialInfo,
        bankName,
        bankCard,
        bankAccount,
        contactPerson,
        contactPhone,
        isTaxFree: isTaxFree ? 1 : 0,
        province: districts[0],
        city: districts[1],
        district: districts[2],
        fileIds,
        team,
        community,
        teamDivision,
      }
      this.saving = true
      saveUser(params)
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('保存成功')
            this.init()
            if (apply) {
              this.$router.push('/usercenter/project/apply')
            } else {
              this.editing = false
            }
          } else {
            this.$message.warning(data.msg)
          }
          this.saving = false
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
          this.saving = false
        })
    },
    cascaderFilter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    handleUploadDjzsFileChange(info) {
      this.djzsFileList = this.handleUploadFile(info)
    },
    handleUploadDjzsFile2Change(info) {
      this.djzsFileList2 = this.handleUploadFile(info)
    },
    handleUploadZzjgFileChange(info) {
      this.zzjgFileList = this.handleUploadFile(info)
    },
    handleUploadCwzdFileChange(info) {
      this.cwzdFileList = this.handleUploadFile(info)
    },
    handleUploadNbFileChange(info) {
      this.nbFileList = this.handleUploadFile(info)
    },
    handleUploadSjbgFileChange(info) {
      this.sjbgFileList = this.handleUploadFile(info)
    },
    handleUpdatePhone() {
      this.showPhoneModal = true
    },
    submitPhone() {
      let { phone, newPhone, smscode } = this.phoneForm
      this.$message.destroy()
      if (!this.$utils.isValidPhone(phone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.phone.focus()
        return
      }
      if (!this.$utils.isValidPhone(newPhone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.newPhone.focus()
        return
      }
      if (smscode === '') {
        this.$refs.smscode.focus()
        this.$message.warning('请输入短信验证码')
        return
      }
      let params = {
        userId: this.user.id,
        phone,
        newPhone,
        smscode,
      }
      this.phoneSaving = true
      changePhone(params)
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('修改成功')
            this.init()
            this.showPhoneModal = false
          } else {
            this.$message.warning(data.msg)
          }
          this.phoneSaving = false
        })
        .catch(() => {
          this.phoneSaving = false
        })
    },
  },
  created() {
    this.init()
  },
}
</script>
